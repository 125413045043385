import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Header from '../../../components/Header'
import Voltar from '../../../components/Voltar'
import { translate } from '../../../components/Translate'

import { getUser, getUserTypesGroups, getUserTypesAccount, saveUser, changeUserData } from '../../../actions/userActions'
import { getLanguages } from '../../../actions/campaignActions'
import Campaign from './campaign'
//import { account } from '../../../reducers/account'


export class FormAdministradores extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);

	}

	onChange(e) {
		let data = this.props.userData;
		data[e.target.name] = e.target.value;
		if (e.target.name == 'status') {
			data[e.target.name] = (e.target.checked ? 1 : 0);
		}
		console.log(data);
		this.props.changeUserData(data);
		this.setState({ update: true })
	}

	// onSelect (){
	//      this.setState({ update: true })
	// }

	async componentDidMount() {
		await this.props.getUserTypesGroups();
		await this.props.getUserTypesAccount();
		await this.props.getLanguages();

		this.setState({ update: true })
		if (this.props.match.params.id) {
			let user_id = this.props.match.params.id
			if (user_id) {
				await this.props.getUser(user_id);
				this.setState({ update: true })
				gtag('event', 'page_view', {
					'page_title': 'Editar Usuário ' + this.props.userData.name + ' ' + this.props.userData.lastname,
					'page_location': window.location.href
				});
			}
		} else {

			let data = this.props.userData;
			gtag('event', 'page_view', {
				'page_title': 'Novo Usuário',
				'page_location': window.location.href
			});
			this.props.changeUserData(data);

		}
	}

	_save = async (e) => {
		e.preventDefault();
		console.log(this.props.userData);
		this.props.userData.status = this.props.userData.status ? 1 : 0;
		if (this.props.match.params.id) {

			this.props.saveUser(this.props.userData, this.props.match.params.id);
			let user = localStorage.getItem('user');
			user = JSON.parse(user);
			if (user.user_id == this.props.match.params.id) {
				let userData = this.props.userData;
				delete userData.password;
				localStorage.setItem('user', JSON.stringify(userData));
			}
		} else {
			this.props.saveUser(this.props.userData);
		}
	}

	render() {
		const { userData, groups, accounts } = this.props;

		// const { userData, groups } = this.props;

		return (
			<div>
				<Header />
				<div className="alert alert-dark m-0 p-0">
					<div className="container p-0">
						<ol className="breadcrumb">
							<li className="breadcrumb-item"><a href="/administradores/">{translate("Usuários")}</a></li>
							<li className="breadcrumb-item active">{translate(this.props.match.params.id ? "Detalhes" : "Novo")}</li>
						</ol>
					</div>
				</div>

				<div className="container" style={{ paddingBottom: '60px' }}>
					<div >
						<div className="card">
							<div className="card-header">

								<div className="togglebutton pull-right m-1">
									<label>
										<input type="checkbox" name="status" checked={userData['status'] === 1 ? true : false} onChange={this.onChange} />
										<span className="toggle"></span>
									</label>
								</div>
								<h4 className="card-title ">{translate('Dados')}</h4>
							</div>

							<div className="card-body">
								<div className="form-row">
									<div className="form-group col-md-2">
										<label>{translate('Tipo')}</label>
										<select className="form-control" name="group_id" onChange={this.onChange} value={userData['group_id']} required>
											<option value=''>{translate('Selecione um tipo')}</option>
											{
												groups.map(group => {
													return (
														<option value={group.group_id} key={group.group_id}>{translate(group.description)}</option>
													)
												})
											}
										</select>
									</div>
									<div className="form-group col-md-2">
										<label>{translate('Conta')}</label>
										<select className="form-control" name="account_id" onChange={this.onChange} value={userData['account_id'] ? userData['account_id'] : ''} required>
											<option value=''>{translate('Selecione uma Conta')}</option>
											{
												accounts.map(account => {
													return (
														<option value={account.account_id} key={account.account_id}>{account.name}</option>
													)
												})
											}
										</select>
									</div>
									<div className="form-group col-md-4">
										<label>{translate('Nome')}</label>
										<input type="text" className="form-control" placeholder={translate('Nome')} name="name" onChange={this.onChange} value={userData['name']} required />
									</div>
									<div className="form-group col-md-4">
										<label>{translate('Sobrenome')}</label>
										<input type="text" className="form-control" placeholder={translate("Sobrenome")} name="lastname" onChange={this.onChange} value={userData['lastname']} required />
									</div>
								</div>
								<div className="form-row">
									<div className="form-group col-md-2">
										<label>{translate('Idioma')}</label>
										<select className="form-control" name="language" onChange={this.onChange} value={userData['language']}>
											<option value=''>{translate('Selecione um idioma')}</option>
											{
												this.props.languages.map(language => {
													return (
														<option value={language} key={language}>{translate(language)}</option>
													)
												})
											}
										</select>
									</div>
									<div className="form-group col-md-4">
										<label>{translate('Email')}</label>
										<input type="email" className="form-control" placeholder={translate("Email")} name="email" onChange={this.onChange} value={userData['email']} required />
									</div>
									<div className="form-group col-md-3">
										<label>{translate('Senha')}</label>
										<input type="password" className="form-control" placeholder={translate("Senha")} name="password" onChange={this.onChange} value={userData['password']} />
									</div>
									<div className="form-group col-md-3">
										<label>{translate('Telefone')}</label>
										<input type="tel" className="form-control" placeholder={translate("Telefone")} name="phone" onChange={this.onChange} value={userData['phone']} required />
									</div>

								</div>
								<Campaign idContact={this.props.match.params.id} />

							</div>
						</div>

						<div className="row justify-content-center">
							<div className="col-sm-3">
								{(this.props.loading ? <button className="btn btn-success btn-block disabled" disabled><span className="fa fa-refresh fa-spin"></span></button>
									:
									<button className="btn btn-success btn-block btn-round" onClick={this._save}>{translate('Salvar')}</button>
								)}


							</div>

						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = store => ({
	userData: store.user.edit_user,
	loading: store.user.loading_save,
	saveUser: saveUser,
	groups: store.user.groups,
	accounts: store.user.account,
	languages: store.campaign.languages
})

const mapDispatchToProps = dispatch =>
	bindActionCreators({ getUserTypesGroups, getUserTypesAccount, getUser, saveUser, changeUserData, getLanguages }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormAdministradores)
