const initialState = {
	isLogged: false,
	loading_login: false,
	response_login: false,
	loading_save: false,
	list: [],
	data: false,
	edit_user: {
		"name": "",
		"lastname": "",
		"email": "",
		"phone": "",
		"group_id": "",
		"password": "",
		"status": 1
	},
	groups: [],
	account: []
}

export function user(state = initialState, action) {
	let list = state.list;
	switch (action.type) {
		case "LOADING_LIST_USERS":
			return Object.assign({}, state, { list: action.response });
		case "LOADING_LOGIN":
			return Object.assign({}, state, { loading_login: true });
		case "LOGIN_SUCCESS":
			return Object.assign({}, state, { loading_login: false, isLogged: true, data: action.response });
		case "LOGIN_ERROR":
			return Object.assign({}, state, { response_login: action.response, loading_login: false });
		case "USER_DATA":
			return Object.assign({}, state, { loading_login: false, isLogged: true, data: action.response });
		case "SAVE_COMPLETE":
			return Object.assign({}, state, { loading_login: false, response_save: action.response });
		case "CHANGE_USER_DATA":
			return Object.assign({}, state, { edit_user: action.edit_user });
		case "LOADING_LEMBRETE":
			return Object.assign({}, state, { lembrete_loading: true, lembrete_response: false });
		case "RESPONSE_LEMBRETE":
			return Object.assign({}, state, { lembrete_loading: false, lembrete_response: action.response });
		case "RESPONSE_USERS":
			return Object.assign({}, state, { list_loading: false, list: action.response });
		case "RESPONSE_DELETE_USER":
			list.splice(action.index, 1);
			return Object.assign({}, state, { list: list });
		case "RESPONSE_USER_TYPES":
			return Object.assign({}, state, { list_loading: false, groups: action.response });
		case "RESPONSE_USER_TYPES_ACCOUNT":
			return Object.assign({}, state, { list_loading: false, account: action.response });
		case "GET_USER_EDIT":
			let data = action.response;
			data.status = (data.status ? 1 : 0);
			console.log(data);
			return Object.assign({}, state, { edit_user: data });
		case "LOADING_SAVE_USER":
			return Object.assign({}, state, { loading_save: action.loading_save });
		default:
			return state
	}
}
